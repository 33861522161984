import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var View = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M256 407c-92 0-180-47-240-131l-8-10 8-10c60-84 148-131 240-131s180 47 240 131l8 10-8 10c-60 84-148 131-240 131zM33 266c55 78 137 121 223 121s168-45 223-121c-55-78-137-121-223-121S88 188 33 266zm223 88c-49 0-88-39-88-88s39-88 88-88 88 39 88 88-39 88-88 88zm0-156c-37 0-68 31-68 68s31 68 68 68 68-31 68-68-31-68-68-68z',
      key: 'k0'
    })
  );
});
View.displayName = 'View';
export var ViewDimensions = { height: 24, width: 24 };
