import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var MessageUnread = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M451 233V108v-2-2c0-2-2-2-2-2s0-2-3-2h-4H20h-6s-2 0-2 2l-2 2v262c0 6 4 11 10 11h277c18 34 53 57 96 57 60 0 109-49 109-109 0-39-21-71-51-92zm-43-114L229 246 51 119zm-2 251c0 7-7 13-13 13s-12-6-12-13c0-6 6-12 12-12s13 6 13 12zm-7-26c0 4-2 6-6 6s-6-2-6-6l-6-53c0-5 2-9 6-9h14c5 0 7 4 7 9zm31-121c-12-4-24-6-37-6-59 0-108 49-108 108 0 11 2 21 4 31H31V127l194 139c2 2 4 2 6 2s5 0 7-2l192-139z',
      key: 'k0'
    })
  );
});
MessageUnread.displayName = 'MessageUnread';
export var MessageUnreadDimensions = { height: 24, width: 24 };
