import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var Chat = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M174 196c0 7-5 13-12 13s-12-6-12-13c0-6 5-12 12-12s12 6 12 12zm64 0c0 7-6 13-13 13s-12-6-12-13c0-6 5-12 12-12s13 6 13 12zm63 0c0 7-5 13-12 13s-13-6-13-13c0-6 6-12 13-12s12 6 12 12zm25 185c0 6-6 12-13 12-6 0-12-6-12-12 0-7 6-13 12-13 7 0 13 6 13 13zm51 0c0 6-6 12-12 12-7 0-13-6-13-12 0-7 6-13 13-13 6 0 12 6 12 13zm51 0c0 6-5 12-12 12s-13-6-13-12c0-7 6-13 13-13s12 6 12 13zm55 69v-8c13-20 17-45 17-67v-7c-2-43-25-84-60-108 0-8 2-14 2-23 0-57-20-112-61-153s-94-66-154-66c-57 0-112 21-153 62-41 39-64 92-66 147v8c0 37 10 74 27 105 4 6 4 12 2 18L8 446l84-28c6-2 14-2 21 2 32 20 71 30 110 30h21c26 39 69 64 116 64h2c25 0 50-6 72-21 2-2 6-2 10-2l60 21zm-260-18c-37 0-69-10-100-29-10-6-25-8-37-4l-45 14 16-49c4-12 2-24-4-35-14-26-22-57-24-88v-10c0-53 22-102 59-137s88-57 139-55c54 0 103 22 138 59 36 37 57 88 55 139v11c-19-11-41-15-62-15-77 0-139 66-139 142 0 18 4 38 12 55-2 2-4 2-8 2zm242 24l6 25-20-8c-9-2-19-2-27 2-18 10-39 16-62 16-43 0-83-22-104-59-10-19-16-39-16-60 0-65 53-120 118-120 23 0 46 6 66 18 35 23 55 59 55 102v5c0 18-6 36-14 53-4 10-6 18-2 26z',
      key: 'k0'
    })
  );
});
Chat.displayName = 'Chat';
export var ChatDimensions = { height: 24, width: 24 };
