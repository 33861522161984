import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var WelcomePack = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M451 39H328V28c0-6-4-10-11-10H197c-7 0-11 4-11 10v13H61c-28 0-51 22-51 51v373c0 28 23 51 51 51h390c28 0 51-23 51-51V90c0-27-23-51-51-51zm-244 0l98-2v96l-43-33c-4-2-8-2-12 0l-43 33zm274 424c0 16-14 30-30 30H61c-16 0-30-14-30-30V90c0-17 14-31 30-31h125v94c0 4 2 9 7 9 4 2 8 2 10 0l53-41 53 41c2 2 4 2 6 2s4 0 4-2c5-2 7-7 7-9V59h123c16 0 30 14 30 31v373zm-84-275H115c-6 0-11 6-11 10 0 5 5 11 11 11h282c6 0 11-4 11-11 0-6-5-10-11-10zm0 68H115c-6 0-11 4-11 10s5 10 11 10h282c6 0 11-4 11-10s-5-10-11-10zm0 67H115c-6 0-11 4-11 11 0 6 5 10 11 10h282c6 0 11-4 11-10 0-7-5-11-11-11z',
      key: 'k0'
    })
  );
});
WelcomePack.displayName = 'WelcomePack';
export var WelcomePackDimensions = { height: 24, width: 24 };
