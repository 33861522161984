import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var ArrowUp = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 532 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M0 389c0-6 2-10 6-14l246-246c4-4 8-6 14-6s10 2 14 6l246 246c8 8 8 20 0 28s-20 8-29 0L266 172 35 403c-9 8-21 8-29 0-4-4-6-8-6-14z',
      key: 'k0'
    })
  );
});
ArrowUp.displayName = 'ArrowUp';
export var ArrowUpDimensions = { height: 24, width: 24 };
