import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var MessageRead = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M502 207v-2c-2-5-6-7-10-7h-62V35c0-7-4-11-10-11H92c-6 0-10 4-10 11v163H20c-4 0-8 2-10 7v294c0 7 4 11 10 11h472c6 0 10-4 10-11zm-41 10l-29 20v-20zM412 45v209L256 364 100 254V45zM82 217v20l-29-20zM31 487V227l219 158c2 2 4 2 6 2s4 0 6-2l219-158v260zm125-354h202c7 0 11-4 11-10 0-7-7-11-13-11H156c-6 0-11 4-11 11 0 6 5 10 11 10zm0 51h202c7 0 11-4 11-10s-4-10-11-10H156c-6 0-11 6-11 10s5 10 11 10zm0 53h202c7 0 11-4 11-10s-4-10-11-10H156c-6 0-11 4-11 10s5 10 11 10z',
      key: 'k0'
    })
  );
});
MessageRead.displayName = 'MessageRead';
export var MessageReadDimensions = { height: 24, width: 24 };
