import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var HelpfulDoc = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M463 137L342 16H106c-32 0-59 27-59 60v380c0 33 27 60 59 60h302c32 0 59-27 59-60V141zM106 495c-20 0-38-16-38-39V76c0-21 16-39 38-39h220v118h118v301c0 21-16 39-38 39H106zm324-360h-84V51zM256 377c-6 0-10-5-10-11V213c0-6 4-10 10-10s10 4 10 10v153c0 6-4 11-10 11zm0-201c-6 0-10-4-10-10s4-11 10-11 10 5 10 11c0 4-4 10-10 10z',
      key: 'k0'
    })
  );
});
HelpfulDoc.displayName = 'HelpfulDoc';
export var HelpfulDocDimensions = { height: 24, width: 24 };
