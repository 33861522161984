import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var ArrowLeft = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M379 532c-6 0-10-2-14-6L119 280c-4-4-6-8-6-14s2-10 6-14L365 6c8-8 20-8 28 0s8 20 0 29L162 266l231 231c8 9 8 21 0 29-4 4-8 6-14 6z',
      key: 'k0'
    })
  );
});
ArrowLeft.displayName = 'ArrowLeft';
export var ArrowLeftDimensions = { height: 24, width: 24 };
