import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var Play = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M489 248L41 24c-14-8-31 2-31 19v448c0 15 17 25 29 19l450-224c17-10 17-30 0-38z',
      key: 'k0'
    })
  );
});
Play.displayName = 'Play';
export var PlayDimensions = { height: 24, width: 24 };
