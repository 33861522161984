import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var MailUnread = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M492 422H20c-6 0-10-4-10-11V121c0-2 0-5 2-7l2-2c2 0 4-2 6-2h472c2 0 4 0 6 2l2 2c2 2 2 5 2 7v290c0 7-4 11-10 11zM31 401h450V141L262 299c-4 2-8 2-12 0L31 141zm22-270l203 145 205-145z',
      key: 'k0'
    })
  );
});
MailUnread.displayName = 'MailUnread';
export var MailUnreadDimensions = { height: 24, width: 24 };
