"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bodyStyles = exports.GlobalStyle = void 0;
var _styledComponents = require("styled-components");
const bodyStyles = exports.bodyStyles = (0, _styledComponents.css)`
  font-family: ${props => props.theme.typography.type.primary};
  font-size: 14px;
  font-weight: 300;
  letter-spacing: ${props => props.theme.typography.letterSpacing};
  color: ${props => props.theme.colors.primaryGray};
`;
const GlobalStyle = exports.GlobalStyle = (0, _styledComponents.createGlobalStyle)`
  :focus {
    outline: none !important;
  }
  body {
    ${bodyStyles}
  }
`;