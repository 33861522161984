import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var Pdf = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M251 410h-10v53h8c9 0 14-1 20-7 5-5 7-11 7-20 0-7-2-15-7-18-4-4-11-8-18-8zm-70 2h-7v24h5c9 0 17-5 17-13 0-7-6-11-15-11zM37 28v478h438V28zm166 412c-4 5-13 9-24 9h-5v27h-15v-75c6-2 13-2 22-2s18 2 22 6c5 4 7 11 7 16 0 10-2 15-7 19zm77 25c-8 8-20 11-35 11-7 0-13 0-18-2v-75c5-1 14-1 22-1 14 0 23 3 31 9 7 7 13 16 13 27 0 15-6 26-13 31zm73-53h-29v19h27v12h-27v31h-15v-76h44zm106-34H53V45h406zm-262-97c-7 3-14 5-21 9 14-20 42-72 60-117 4-8 5-15 9-22 18 42 44 78 73 108-36 0-80 7-121 22zm59-174c4-17 2-28 0-31-4-4-13-2-18 3-9 10-9 30 3 63 2 3 2 5 4 9 6-17 9-31 11-44zM132 315c-13 11-17 19-13 22h4c3 0 7-2 12-5 11-7 26-22 41-42-19 7-33 16-44 25zm232-23c15 9 25 5 29-2 4-8-5-20-24-26-14-5-31-7-51-7 15 14 29 25 46 35zm44 5c-8 18-29 24-53 11-28-15-53-38-75-64 35-4 67-4 93 6 27 9 42 29 35 47zM247 193c-9 22-20 46-31 64 22-7 44-11 66-15-15-14-26-31-35-49zm-59 106c-16 20-31 36-44 46-7 5-12 7-20 9-7 2-14 0-18-8-5-7-7-16-4-25 4-7 10-15 19-22 16-13 40-26 69-37 7-3 17-5 24-7-9 20-18 35-26 44zm37-150c-15-37-15-64 0-79 11-12 31-14 42-5 9 7 11 24 7 47-3 19-11 42-22 70-1 4-3 9-5 15-9-19-17-33-22-48z',
      key: 'k0'
    })
  );
});
Pdf.displayName = 'Pdf';
export var PdfDimensions = { height: 24, width: 24 };
