import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var AddAccount = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M354 512H39c-14 0-27-13-27-27v-59c0-10 6-21 17-23l118-49c-24-27-43-70-43-111 0-69 48-102 93-102s92 33 92 102c0 41-17 84-43 109v2l119 49c10 4 16 13 16 23v59c-2 17-12 27-27 27zM197 164c-35 0-72 24-72 82 0 34 14 73 37 94 6 6 8 14 6 22-2 6-6 13-12 15L37 426c-2 0-4 2-4 4v59c0 2 2 6 6 6h315c4 0 6-2 6-6v-59c0-2 0-4-4-4l-118-49c-9-7-13-15-13-19-2-8 2-14 6-20 21-21 37-60 37-95 0-55-37-79-71-79zm198 63c-57 0-104-47-104-104 0-58 47-105 104-105 58 0 105 47 105 105 0 57-47 104-105 104zm0-186c-47 0-84 37-84 84s37 84 84 84 84-37 84-84-37-84-84-84zm45 78h-39V80c0-2-2-4-4-4s-4 2-4 4v39h-39c-2 0-4 2-4 4s2 4 4 4h39v39c0 2 2 4 4 4s4-2 4-4v-37h39c2 0 4-2 4-4s-2-6-4-6z',
      key: 'k0'
    })
  );
});
AddAccount.displayName = 'AddAccount';
export var AddAccountDimensions = { height: 24, width: 24 };
