import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var Trash = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M492 114H369V30c0-6-4-10-11-10H154c-7 0-11 4-11 10v84H20c-6 0-10 5-10 11s4 10 10 10h43l21 367c0 6 4 10 10 10h322c6 0 10-4 10-10l20-367h43c7 0 11-4 11-10 2-6-2-11-8-11zM164 41h184v73H164zm244 450H104L84 135h342zm-152-82c6 0 10-4 10-10V203c0-7-4-11-10-11s-10 6-10 11v196c0 4 4 10 10 10zm72 0c6 0 10-4 10-10V203c0-7-4-11-10-11s-11 4-11 11v196c0 4 5 10 11 10zm-146 0c6 0 11-4 11-10V203c0-7-5-11-11-11s-10 6-10 11v196c0 4 4 10 10 10z',
      key: 'k0'
    })
  );
});
Trash.displayName = 'Trash';
export var TrashDimensions = { height: 24, width: 24 };
