import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var AccountSettings = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M303 508c-8 0-14-4-18-13l-13-34-2-2h-28s-2 0-2 2l-13 34c-4 11-14 15-24 13l-47-15c-11-4-17-14-13-24l11-35v-2l-25-16h-2l-29 20c-8 6-20 4-26-4l-29-39c-6-8-4-21 4-27l29-20v-2l-8-29-2-2H31c-11 0-19-8-19-18v-49c0-11 8-19 19-19h35s2 0 2-2l8-29v-2l-29-20c-8-12-10-23-4-33l29-39c6-8 18-10 26-4l29 21h2l25-17v-2l-9-35c-2-10 2-20 13-24l47-15c10-4 20 2 24 13l11 32 2 2h28s2 0 2-2l13-34c4-11 14-15 24-13l47 15c11 4 17 14 13 24l-11 35v2l25 16h2l29-20c8-6 20-4 26 4l29 39c6 8 4 21-4 27l-29 20v2l8 29 2 2h35c11 0 19 8 19 18v49c0 11-8 19-19 19h-35s-2 0-2 2l-8 29v2l29 20c8 6 10 19 4 27l-29 39c-6 8-18 10-26 4l-29-21h-2l-25 17v2l11 35c2 10-2 20-13 24l-47 14c-2 9-4 9-6 9zm-61-70h28c11 0 19 6 21 14l12 35 45-16-12-33c-2-8 0-18 8-25l25-16c8-6 18-6 26 0l29 21 27-39-29-21c-8-6-10-16-8-24l8-29c2-8 12-16 20-16h35l-2-48h-35c-10 0-18-6-20-16l-8-29c-2-8 0-18 8-24l29-21-29-37-27 21c-8 6-18 6-26 0l-25-16c-8-7-10-17-8-25l14-35-45-14-10 33c-2 8-12 14-21 14h-28c-11 0-19-6-21-14l-12-35-47 18 12 33c2 8 0 18-8 25l-25 16c-8 6-18 6-26 0l-29-21-27 39 29 21c8 6 10 16 8 24l-8 29c-2 8-12 16-20 16H35l2 48h35c10 0 18 6 20 16l8 29c2 8 0 18-8 24l-29 21 29 37 27-21c8-6 18-6 26 0l25 16c8 7 10 17 8 25l-14 35 45 12 10-33c4-8 12-14 23-14zm14-47c-68 0-125-55-125-125s55-125 125-125 125 55 125 125-57 125-125 125zm0-229c-57 0-104 47-104 104s47 104 104 104 104-47 104-104-47-104-104-104z',
      key: 'k0'
    })
  );
});
AccountSettings.displayName = 'AccountSettings';
export var AccountSettingsDimensions = { height: 24, width: 24 };
