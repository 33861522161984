import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var Trophy = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M360 512H152c-7 0-11-4-11-10 0-35 45-64 105-68v-31c-53-2-99-31-127-71C8 305 10 108 10 100c0-6 4-10 10-10h70V30c0-6 4-10 10-10h312c6 0 10 4 10 10v60h70c6 0 10 4 10 10 0 8 4 207-109 232-28 40-74 67-127 71v31c60 2 105 31 105 68 0 4-4 10-11 10zm-196-21h184c-10-18-43-37-92-37s-82 17-92 37zM111 41v196c0 80 65 144 143 144 80 0 147-66 147-148V41zm-80 69c0 37 8 158 73 193-8-21-14-43-14-66V110zm391 0v125c0 25-6 47-14 68 63-35 71-158 73-193z',
      key: 'k0'
    })
  );
});
Trophy.displayName = 'Trophy';
export var TrophyDimensions = { height: 24, width: 24 };
