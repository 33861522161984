import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var DashboardTour = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M504 160H8V59h496zM29 139h454V80H29zm475 121H303v-76h201zm-180-21h159v-34H324zm180 127H303v-77h201zm-180-20h159v-37H324zm180 127H303v-78h201zm-180-21h159v-36H324zm-48 21H8V184h268zM29 452h227V205H29z',
      key: 'k0'
    })
  );
});
DashboardTour.displayName = 'DashboardTour';
export var DashboardTourDimensions = { height: 24, width: 24 };
