import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var UploadDocuments = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M319 329l-55-53c-2-2-4-4-8-4s-6 2-8 4l-53 53c-5 5-5 11 0 15 2 2 6 2 8 2s4 0 6-2l37-37v133c0 6 4 10 10 10s10-4 10-10V307l39 37c4 4 10 4 14 0 5-4 5-10 0-15zm74 80h-35c-6 0-10-4-10-10s4-10 10-10h35c51 0 88-33 88-78 0-37-24-70-57-76l-8-2v-8c-2-27-13-49-33-61-18-11-41-13-59-4l-9 4-4-9c-18-32-53-53-92-53-29 0-57 12-76 35-20 23-30 51-28 82v10h-11c-38 2-71 37-71 76 0 45 35 82 78 82h45c4 2 8 6 8 12s-4 10-10 10h-45c-54 0-99-47-99-102 0-47 35-88 82-96 0-33 12-64 35-88 25-27 57-41 92-41 43 0 82 20 105 57 22-8 49-4 69 8 25 15 39 39 43 70 39 12 68 51 68 94-2 55-49 98-111 98z',
      key: 'k0'
    })
  );
});
UploadDocuments.displayName = 'UploadDocuments';
export var UploadDocumentsDimensions = { height: 24, width: 24 };
