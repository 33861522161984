import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var BusinessHours = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M256 514C119 514 8 403 8 266S119 18 256 18s248 111 248 248-111 248-248 248zm0-475C131 39 29 141 29 266s102 227 227 227 227-102 227-227S381 39 256 39zm84 327c-2 0-6-2-8-4l-84-86V121c0-7 4-11 10-11s10 4 10 11v147l80 82c4 4 4 10 0 14-4 2-6 2-8 2z',
      key: 'k0'
    })
  );
});
BusinessHours.displayName = 'BusinessHours';
export var BusinessHoursDimensions = { height: 24, width: 24 };
