import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var Printer = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M414 198c-21 0-39 19-39 39 0 21 16 39 39 39 22 0 39-18 39-39 0-20-19-39-39-39zm0 58c-11 0-19-8-19-19 0-10 8-18 19-18 10 0 18 8 18 18-2 11-10 19-18 19zm39-109h-23V37c0-9-8-17-16-17H98c-8 0-16 8-16 17v110H59c-26 0-49 23-49 51v172c0 29 23 52 49 52h23v75c0 9 8 17 16 17h314c8 0 16-8 16-17v-77h23c26 0 49-23 49-52V196c2-26-21-49-47-49zM408 41v106H104V41zm0 450H104V338h306v153zm73-121c0 17-12 31-28 31h-23v-82H84v82H59c-16 0-28-14-28-31V196c0-16 12-30 28-30h392c16 0 28 14 28 30v174z',
      key: 'k0'
    })
  );
});
Printer.displayName = 'Printer';
export var PrinterDimensions = { height: 24, width: 24 };
