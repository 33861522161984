"use strict";

var _interopRequireDefault = require("/home/circleci/project/client-dashboard/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BreadcrumbsItem = exports.Breadcrumbs = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
const Breadcrumbs = exports.Breadcrumbs = _styledComponents.default.ul`
  font-family:
    Open Sans,
    sans-serif;
  color: ${props => props.theme.colors.primaryGray};
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
`;
const BreadcrumbsItem = exports.BreadcrumbsItem = _styledComponents.default.li`
  display: inline-block;
  &::after {
    content: '>';
    transform: scaleY(1.75);
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    color: ${props => props.theme.colors.tertiaryBlueLight};
    margin-left: 7px;
    margin-right: 7px;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  a {
    display: inline-block;
    font-size: 14px;
    line-height: 14px;
    margin-top: 2px;
    font-weight: 700;

    &:hover {
      opacity: 0.7;
    }
  }
`;