import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var ProgramResources = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M453 49h-9V28c0-6-4-10-10-10s-10 4-10 10v21h-88V28c0-6-4-10-10-10-7 0-11 4-11 10v21h-88V28c0-6-4-10-10-10s-10 4-10 10v21h-6c-25 0-45 16-49 39H59c-28 2-51 24-51 53v322c0 28 23 51 51 51h254c25 0 45-17 49-39h93c28 0 51-23 51-51V100c-2-27-25-51-53-51zm30 375c0 16-14 30-30 30H201c-17 0-31-14-31-30V100c0-16 14-31 31-31h254c16 0 30 15 30 31v324zm-172 69H59c-16 0-30-14-30-30V141c0-16 14-31 30-31h91v95H57c-6 0-10 4-10 10s4 10 10 10h93v66H57c-6 0-10 4-10 10s4 10 10 10h93v66H57c-6 0-10 4-10 10s4 10 10 10h93v25c0 28 22 51 51 51h141c-6 12-16 20-31 20zm144-327H199c-6 0-11 4-11 10s5 10 11 10h258c6 0 10-4 10-10s-6-10-12-10zm0 86H199c-6 0-11 4-11 10s5 10 11 10h258c6 0 10-4 10-10s-6-10-12-10zm0 86H199c-6 0-11 4-11 10s5 10 11 10h258c6 0 10-4 10-10s-6-10-12-10z',
      key: 'k0'
    })
  );
});
ProgramResources.displayName = 'ProgramResources';
export var ProgramResourcesDimensions = { height: 24, width: 24 };
