import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var Doc = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M463 137L342 16H106c-32 0-59 27-59 60v380c0 33 27 60 59 60h302c32 0 59-27 59-60V141zM106 495c-20 0-38-16-38-39V76c0-21 16-39 38-39h220v118h118v301c0 21-16 39-38 39zm324-360h-84V51zm-168 55c-4-4-10-4-14 0l-78 72c-4 4-4 10 0 14 2 2 4 4 8 4 2 0 4 0 6-2l62-57 2 147c0 7 4 11 10 11s10-4 10-11l-2-147 62 55c4 4 10 4 14 0s4-10 0-14z',
      key: 'k0'
    })
  );
});
Doc.displayName = 'Doc';
export var DocDimensions = { height: 24, width: 24 };
