import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var Edit = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M496 119l-95-95c-4-4-10-4-14 0L49 364c-2 2-2 2-2 4L12 497c0 5 0 9 2 11s4 2 9 2h2l129-35c2 0 2-2 4-2l338-340c2-2 2-4 2-8s0-4-2-6zM152 450l-80-80 239-241 80 80zm-91-59l72 70-94 24zm347-197l-80-80 67-67 80 80z',
      key: 'k0'
    })
  );
});
Edit.displayName = 'Edit';
export var EditDimensions = { height: 24, width: 24 };
