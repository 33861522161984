import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var AddDeposit = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M256 370c-57 0-104-47-104-104s47-104 104-104 104 47 104 104-47 104-104 104zm0-188c-47 0-84 37-84 84s37 84 84 84 84-37 84-84-37-84-84-84zm246 225H10V125h494v282zM31 387h452V145H31zm227-176c2 0 4 2 4 4v4c8 0 14 4 23 8 2 2 2 2 2 4s-2 4-4 4h-2c-7-4-11-6-17-8v33c21 4 29 12 29 24 0 15-12 23-27 25v8c0 2-2 4-4 4s-4-2-4-4v-10c-10 0-20-4-29-12-2-2-2-2-2-4s2-5 4-5 2 0 2 3c7 6 15 10 23 10v-33c-23-4-31-10-31-25 0-14 11-22 27-24v-2c2-2 4-4 6-4zm-4 47v-31c-10 0-18 6-18 14 0 9 4 13 18 17zm6 10v31c10 0 19-6 19-15 0-8-5-12-19-16z',
      key: 'k0'
    })
  );
});
AddDeposit.displayName = 'AddDeposit';
export var AddDepositDimensions = { height: 24, width: 24 };
