import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var SubmitRequest = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M453 442H313c-6 0-10-4-10-10s4-10 10-10h140c16 0 28-15 28-31V141c0-16-12-31-28-31H59c-16 0-28 15-28 31v250c0 16 12 31 28 31h140c6 0 10 4 10 10s-4 10-10 10H59c-26 0-49-22-49-51V141c0-29 23-51 49-51h396c26 0 49 22 49 51v250c0 29-23 51-51 51zM162 325c-2 0-6-2-8-4-4-4-4-10 0-14l96-86c4-4 10-4 14 0l94 86c4 4 4 10 0 14s-10 4-14 0l-88-80-88 80c-2 2-4 4-6 4zm94 117c-6 0-10-4-10-10V231c0-6 4-10 10-10s10 4 10 10v201c0 6-4 10-10 10z',
      key: 'k0'
    })
  );
});
SubmitRequest.displayName = 'SubmitRequest';
export var SubmitRequestDimensions = { height: 24, width: 24 };
