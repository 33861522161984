"use strict";

var _interopRequireDefault = require("/home/circleci/project/client-dashboard/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FooterSeparator = exports.FooterItem = exports.Footer = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
const Footer = exports.Footer = _styledComponents.default.footer`
  clear: both;
  color: ${props => props.theme.colors.plain};
  font-size: 12px;
  font-family:
    Open Sans,
    sans-serif;
  padding-top: 40px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  background: ${props => props.theme.colors.secondaryNavy};
`;
const FooterItem = exports.FooterItem = _styledComponents.default.span`
  display: ${props => props.inline ? 'inline-block' : 'block'};
  line-height: ${props => props.inline ? '15px' : '30px'};
  font-weight: 300;

  padding-left: ${props => props.inline ? '15px' : '0px'};
  padding-right: ${props => props.inline ? '15px' : '0px'};
  border-left: ${props => props.separator ? '1px solid #fff' : 'none'};

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  a {
    color: ${props => props.theme.colors.plain};
    cursor: pointer;
    font-size: 12px;
  }
`;
const FooterSeparator = exports.FooterSeparator = _styledComponents.default.hr`
  opacity: 0.25;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 0;
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.grayRegular};
`;