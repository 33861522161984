import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var Alert = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M500 467L264 57c-2-6-12-6-16 0L12 467c-2 4-2 8 0 10s4 4 8 4h472c4 0 6 0 8-4s2-8 0-10zm-244-76c-12 0-20-8-20-21 0-12 8-20 20-20s20 8 20 20c0 13-8 21-20 21zm12-70c0 6-6 11-12 11s-10-5-12-11l-11-92c0-6 5-12 11-12h24c6 0 11 6 11 12z',
      key: 'k0'
    })
  );
});
Alert.displayName = 'Alert';
export var AlertDimensions = { height: 24, width: 24 };
