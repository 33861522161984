import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var Logout = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M420 356c-2 0-4 0-6-2-4-4-4-10 0-14l65-72-67-74c-4-4-4-10 0-14s10-4 14 0l72 80c4 4 4 10 0 14l-72 78c0 2-4 4-6 4zm67-80H319c-6 0-10-4-10-10s4-10 10-10h168c7 0 11 4 11 10s-4 10-11 10zM219 475c-115 0-209-94-209-209S104 57 219 57c62 0 121 27 160 76 4 4 2 10-2 14s-10 2-15-2c-36-43-88-67-143-67-104 0-188 84-188 188s84 188 188 188c55 0 109-24 143-67 5-4 11-4 15-2 4 4 4 10 2 14-39 47-98 76-160 76z',
      key: 'k0'
    })
  );
});
Logout.displayName = 'Logout';
export var LogoutDimensions = { height: 24, width: 24 };
