import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var Call = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 513.5 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M365 516c-7 0-15 0-23-2-47-8-98-39-160-101l-69-67C49 284 16 235 8 188 0 135 18 86 70 33c10-11 20-19 28-21 15-4 25-2 37 10l2 2 64 82c8 15 10 25 8 35-4 6-10 14-19 21-22 20-30 43-28 65 2 25 18 45 31 55l49 50c12 12 32 28 57 28 23 2 45-8 63-31 7-8 13-14 21-18 10-4 20-2 33 6l84 64c12 12 16 22 12 37-2 8-8 18-20 30-41 47-82 68-127 68zM111 35h-5c-2 0-10 4-20 14-45 49-61 90-53 133s39 88 98 145l70 68c59 57 106 88 147 94 45 6 84-10 131-59 10-10 13-19 15-21 2-4 2-6-5-12l-81-61c-7-2-11-4-13-2l-10 10c-31 37-61 41-84 39-31-2-55-21-72-37l-49-49c-16-15-35-39-39-72-2-20 2-53 37-86 0 2 6-4 6-6s0-8-4-14l-63-80c-2-4-4-4-6-4z',
      key: 'k0'
    })
  );
});
Call.displayName = 'Call';
export var CallDimensions = { height: 24, width: 24 };
