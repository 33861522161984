import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var BankAccountChange = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M258 248c2 0 6 2 6 6v6c12 2 21 4 29 10 2 2 4 4 4 6 0 4-2 6-6 6-2 0-2 0-4-2-8-6-15-8-23-10v43c27 6 37 16 37 33 0 18-14 31-37 33v12c0 2-2 6-6 6-2 0-6-2-6-6v-12c-14-2-27-7-37-15-2-2-2-4-2-6 0-4 2-6 6-6 2 0 4 0 4 2 8 8 19 12 31 14v-43c-25-6-37-16-37-32 0-19 14-31 37-33v-6c-2-4 0-6 4-6zm-6 63v-41c-14 0-23 8-23 19 0 10 4 16 23 22zm10 14v41c14 0 25-8 25-20 0-8-6-14-25-21zm232 187H18c-6 0-10-4-10-10v-64c0-6 4-10 10-10h33V227H18c-4 0-8-2-10-6s0-8 4-12L250 22c4-2 8-2 12 0l238 187c4 2 4 8 4 12s-6 6-10 6h-33v201h33c6 0 10 4 10 10v64c0 6-4 10-10 10zM29 491h454v-43H29zm106-63h242V227H135zm262 0h43V227h-43zm-325 0h43V227H72zM47 207h416L254 43z',
      key: 'k0'
    })
  );
});
BankAccountChange.displayName = 'BankAccountChange';
export var BankAccountChangeDimensions = { height: 24, width: 24 };
