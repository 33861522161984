import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var Calendar = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M451 57h-23V30c0-6-4-10-10-10s-10 4-10 10v27h-89V30c0-6-4-10-10-10s-10 4-10 10v27h-88V30c0-6-4-10-10-10s-8 4-8 10v27h-89V30c0-6-4-10-10-10s-10 4-10 10v27H61c-28 0-51 23-51 51v353c0 28 23 51 51 51h390c28 0 51-23 51-51V108c0-28-23-51-51-51zm30 236h-92v-86h90v86zm-112 0H266v-86h103zm-123 0H143v-86h103zm-123 0H31v-86h92zm-92 20h92v84H31zm112 0h103v84H143zm103 105v73H143v-73zm20 0h103v73H266zm0-21v-84h103v84zm123-84h90v84h-90zM61 78h23v14c0 6 4 10 10 10s10-4 10-10V78h89v14c0 6 4 10 10 10s10-4 10-10V78h88v14c0 6 4 10 10 10s11-4 11-10V78h88v14c0 6 4 10 10 10s10-4 10-10V78h21c16 0 30 14 30 30v80H31v-80c0-18 14-30 30-30zM31 461v-43h92v73H61c-16 0-30-14-30-30zm420 30h-62v-73h90v43c2 16-12 30-28 30z',
      key: 'k0'
    })
  );
});
Calendar.displayName = 'Calendar';
export var CalendarDimensions = { height: 24, width: 24 };
