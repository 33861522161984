"use strict";

var _interopRequireDefault = require("/home/circleci/project/client-dashboard/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Image = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
const Image = exports.Image = _styledComponents.default.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;