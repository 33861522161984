import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var LoginSetting = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M391 356c-2 0-6-2-8-4l-72-78c-4-4-4-10 0-14l72-80c4-4 10-4 14 0s4 10 0 14l-65 74 65 72c4 4 4 10 0 14 0 0-2 2-6 2zm101-80H324c-7 0-11-4-11-10s4-10 11-10h168c6 0 10 4 10 10s-4 10-10 10zM219 475c-115 0-209-94-209-209S104 57 219 57c62 0 121 27 160 76 4 4 2 10-2 14s-10 2-15-2c-34-43-88-67-143-67-104 0-188 84-188 188s84 188 188 188c55 0 109-24 143-67 5-4 11-4 15-2 4 4 4 10 2 14-39 47-98 76-160 76zm74-242H88c-6 0-10-4-10-10s4-10 10-10h205c6 0 10 4 10 10s-4 10-10 10zm-33-10c0 16-13 29-29 29-15 0-28-13-28-29s13-29 28-29c16 0 29 13 29 29zm33 94H88c-6 0-10-4-10-10s6-10 12-10h205c6 0 10 4 10 10s-6 10-12 10zm-113-10c0 16-13 29-28 29-16 0-29-13-29-29s13-29 29-29c15 0 28 13 28 29z',
      key: 'k0'
    })
  );
});
LoginSetting.displayName = 'LoginSetting';
export var LoginSettingDimensions = { height: 24, width: 24 };
