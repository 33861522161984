"use strict";

var _interopRequireDefault = require("/home/circleci/project/client-dashboard/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PanelTitleHeading = exports.PanelTitle = exports.Panel = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _atomicLayout = require("atomic-layout");
const Panel = exports.Panel = (0, _styledComponents.default)(_atomicLayout.Box).attrs({
  padding: 16,
  paddingSm: 32,
  marginBottom: 24
}).withConfig({
  displayName: "Panel",
  componentId: "sc-h90bo2-0"
})(["background:#fff;box-shadow:0px 6px 40px -4px rgba(16,24,40,0.11),0px 9px 12px -3px rgba(16,24,40,0.05);line-height:1.5em;border-radius:10px;"]);
const PanelTitle = exports.PanelTitle = (0, _styledComponents.default)(_atomicLayout.Box).attrs(props => ({
  marginBottom: props.large ? 80 : 32,
  paddingBottom: 16,
  ...props
})).withConfig({
  displayName: "Panel__PanelTitle",
  componentId: "sc-h90bo2-1"
})(["color:#000;font-weight:700;border-bottom:", ";"], props => props.large ? 'none' : '1px solid #dcdcdc');
const PanelTitleHeading = exports.PanelTitleHeading = (0, _styledComponents.default)(_atomicLayout.Box).withConfig({
  displayName: "Panel__PanelTitleHeading",
  componentId: "sc-h90bo2-2"
})(["color:#000;line-height:33px;font-weight:700;font-size:", ";"], props => props.large ? '36px' : '24px');