"use strict";

var _interopRequireDefault = require("/home/circleci/project/client-dashboard/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NavTabPanel = exports.NavTabList = exports.NavTab = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _atomicLayout = require("atomic-layout");
var _polished = require("polished");
const NavTab = exports.NavTab = _styledComponents.default.a.withConfig({
  displayName: "NavTabs__NavTab",
  componentId: "sc-15cwhty-0"
})(["text-align:center;text-decoration:none;outline:none;cursor:pointer;position:relative;font-family:", ";font-size:14px;font-weight:700;letter-spacing:0.28px;flex:1;background:", ";border:none;color:", ";border-bottom:1px solid ", ";&:hover{opacity:0.7;}&:not(:last-of-type){border-right:1px solid ", ";}&.active{color:", ";background:", ";border-bottom:1px solid ", ";&:after{", " transform:translateX(-50%);position:absolute;z-index:1;content:'';top:100%;left:50%;}}"], props => props.theme.typography.type.primary, props => props.theme.colors.plain, props => props.theme.colors.secondaryBlue, props => props.theme.colors.grayRegular, props => props.theme.colors.grayRegular, props => props.theme.colors.plain, props => props.theme.colors.secondaryBlue, props => props.theme.colors.secondaryBlue, props => (0, _polished.triangle)({
  pointingDirection: 'bottom',
  width: '24px',
  height: '12px',
  foregroundColor: props.theme.colors.secondaryBlue
}));
const NavTabList = exports.NavTabList = _styledComponents.default.div.withConfig({
  displayName: "NavTabs__NavTabList",
  componentId: "sc-15cwhty-1"
})(["border:1px solid ", ";border-bottom:none;display:flex;font-weight:700;"], props => props.theme.colors.grayRegular);
const NavTabPanel = exports.NavTabPanel = _atomicLayout.Box;