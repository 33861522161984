import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var SystemError = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M383 137H35c-15 0-27-10-27-25V43c0-15 12-25 25-25h348c14 0 25 12 25 25v69c4 15-9 25-23 25zM35 39c-2 0-4 2-4 4v69c0 2 2 4 4 4h348c2 0 6-2 6-4V43c0-2-2-4-4-4zm348 260H35c-15 0-25-13-25-25v-69c0-15 13-25 25-25h348c14 0 25 12 25 25v69c2 12-11 25-25 25zM35 200c-2 0-4 3-4 5v69c0 2 2 4 4 4h348c2 0 4-2 4-4v-69c0-2-2-5-4-5zm364 105c-32 0-61 14-80 39H35c-15 0-25 12-25 24v70c0 14 13 25 25 25h274c19 30 51 51 88 51 58 0 105-47 105-105 0-57-45-104-103-104zm0 188c-24 0-49-12-63-30-6-7-10-13-12-21-5-10-7-22-7-33 0-16 5-32 15-45 4-8 12-14 18-20 15-10 33-19 51-19 48 0 84 37 84 84s-41 84-86 84zM35 444c-2 0-4-2-4-4v-70c0-2 2-4 4-4h270c-6 15-10 29-10 45 0 13 2 23 6 33zm371-84h-15c-4 0-6 2-6 6l2 23 4 33c0 4 2 6 6 6s6-2 6-6l5-33 2-23c2-4 0-6-4-6zm-7 76c-8 0-12 6-12 12s4 11 10 13h2c9 0 13-7 13-13 0-2-2-6-2-8-4-2-7-4-11-4zM158 76c0 6-6 12-13 12-6 0-12-6-12-12 0-7 6-13 12-13 7 0 13 6 13 13zm63 0c0 6-5 12-12 12s-12-6-12-12c0-7 5-13 12-13s12 6 12 13zm64 0c0 6-6 12-13 12-6 0-12-6-12-12 0-7 6-13 12-13 7 0 13 6 13 13zM158 237c0 7-6 13-13 13-6 0-12-6-12-13 0-6 6-12 12-12 7 0 13 6 13 12zm63 0c0 7-5 13-12 13s-12-6-12-13c0-6 5-12 12-12s12 6 12 12zm64 0c0 7-6 13-13 13-6 0-12-6-12-13 0-6 6-12 12-12 7 0 13 6 13 12zM158 403c0 7-6 13-13 13-6 0-12-6-12-13s6-12 12-12c7 0 13 5 13 12zm63 0c0 7-5 13-12 13s-12-6-12-13 5-12 12-12 12 5 12 12zm64 0c0 7-6 13-13 13-6 0-12-6-12-13s6-12 12-12c7 0 13 5 13 12z',
      key: 'k0'
    })
  );
});
SystemError.displayName = 'SystemError';
export var SystemErrorDimensions = { height: 24, width: 24 };
