import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var Checkmark = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M164 485L8 356c-8-8-10-20-2-29 8-8 21-10 29-2l125 103L475 53c8-8 21-10 29-2s10 20 2 29z',
      key: 'k0'
    })
  );
});
Checkmark.displayName = 'Checkmark';
export var CheckmarkDimensions = { height: 24, width: 24 };
