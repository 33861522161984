"use strict";

var _interopRequireDefault = require("/home/circleci/project/client-dashboard/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Tooltip = exports.DarkGlassTooltip = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _polished = require("polished");
var _reactTooltip = _interopRequireDefault(require("react-tooltip"));
const Tooltip = exports.Tooltip = (0, _styledComponents.default)(_reactTooltip.default).attrs(props => ({
  type: 'dark',
  scrollHide: true,
  effect: 'solid',
  delayHide: 1000,
  borderColor: props.theme.colors.secondaryGreen,
  backgroundColor: props.theme.colors.plain,
  clickable: true,
  ...props
})).withConfig({
  displayName: "Tooltip",
  componentId: "sc-1omk5ku-0"
})(["color:", " !important;background:", " !important;font-size:14px !important;border:2px solid ", " !important;padding:10px;border-radius:7px;max-width:300px;&.place-bottom{&::before{border-left:2px solid ", ";border-top:2px solid ", ";border-bottom:none !important;border-right:none !important;top:-8px;margin-left:-7px;}}&.place-top{&::before{border-right:2px solid ", ";border-bottom:2px solid ", ";border-top:none !important;border-left:none !important;margin-left:-6px;bottom:-7px;}}&.place-left{&::before{border-right:2px solid ", ";border-top:2px solid ", ";border-bottom:none !important;border-left:none !important;margin-top:-6px;right:-7px;}}&.place-right{&::before{border-left:2px solid ", ";border-bottom:2px solid ", ";border-top:none !important;border-right:none !important;margin-top:-6px;left:-7px;}}&::before{z-index:100;width:10px;height:10px;background:", ";transform:rotate(45deg);}"], props => props.theme.colors.primaryGray, props => props.theme.colors.plain, props => props.theme.colors.secondaryGreen, props => props.theme.colors.secondaryGreen, props => props.theme.colors.secondaryGreen, props => props.theme.colors.secondaryGreen, props => props.theme.colors.secondaryGreen, props => props.theme.colors.secondaryGreen, props => props.theme.colors.secondaryGreen, props => props.theme.colors.secondaryGreen, props => props.theme.colors.secondaryGreen, props => props.theme.colors.plain);
const DarkGlassTooltip = exports.DarkGlassTooltip = (0, _styledComponents.default)(_reactTooltip.default).attrs(props => {
  return {
    type: 'dark',
    scrollHide: true,
    effect: 'solid',
    offset: {
      top: -40,
      bottom: -40
    },
    delayHide: 1000,
    borderColor: props.theme.colors.secondaryNavy,
    backgroundColor: (0, _polished.rgba)(props.theme.colors.secondaryNavy, 0.95),
    clickable: true,
    ...props
  };
}).withConfig({
  displayName: "Tooltip__DarkGlassTooltip",
  componentId: "sc-1omk5ku-1"
})(["color:", " !important;background:", " !important;font-size:14px !important;border:2px solid ", " !important;padding:10px;border-radius:7px;max-width:300px;&.place-bottom{&::before{border-left:2px solid ", ";border-top:2px solid ", ";border-bottom:none !important;border-right:none !important;top:-8px;margin-left:-7px;}}&.place-top{&::before{border-right:2px solid ", ";border-bottom:2px solid ", ";border-top:none !important;border-left:none !important;margin-left:-6px;bottom:-7px;}}&.place-left{&::before{border-right:2px solid ", ";border-top:2px solid ", ";border-bottom:none !important;border-left:none !important;margin-top:-6px;right:-7px;}}&.place-right{&::before{border-left:2px solid ", ";border-bottom:2px solid ", ";border-top:none !important;border-right:none !important;margin-top:-6px;left:-7px;}}&::before{z-index:100;width:10px;height:10px;background:", " !important;transform:rotate(45deg);}"], props => props.theme.colors.plain, props => (0, _polished.rgba)(props.theme.colors.secondaryNavy, 0.95), props => props.theme.colors.secondaryNavy, props => props.theme.colors.secondaryNavy, props => props.theme.colors.secondaryNavy, props => props.theme.colors.secondaryNavy, props => props.theme.colors.secondaryNavy, props => props.theme.colors.secondaryNavy, props => props.theme.colors.secondaryNavy, props => props.theme.colors.secondaryNavy, props => props.theme.colors.secondaryNavy, props => (0, _polished.rgba)(props.theme.colors.secondaryNavy, 0.75));