"use strict";

var _interopRequireDefault = require("/home/circleci/project/client-dashboard/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccordionItem = exports.AccordionHead = exports.AccordionBody = exports.Accordion = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
const Accordion = exports.Accordion = _styledComponents.default.div`
  display: block;
`;
const AccordionBody = exports.AccordionBody = _styledComponents.default.div`
  display: block;
  height: auto;
  max-height: 4000px;
  font-size: 14px;
  transition: all 300ms ease-in-out;
`;
const AccordionItem = exports.AccordionItem = _styledComponents.default.div`
  display: block;
  transition: max-height 300ms ease-in-out;
  padding-left: 20px;
  overflow: ${props => props.open ? 'inherit' : 'hidden'};
  max-height: ${props => props.open ? '4000px' : '44px'};

  ${AccordionBody} {
    line-height: ${props => props.open ? 1.5 : 0};
    color: ${props => props.open ? '#4a4a4a' : 'transparent'};
    overflow: ${props => props.open ? 'inherit' : 'hidden'};
    visibility: ${props => props.open ? 'visible' : 'hidden'};
  }
`;
const AccordionHead = exports.AccordionHead = _styledComponents.default.div`
  cursor: pointer;
  color: ${props => props.theme.colors.secondaryBlue};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
`;