import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var BrowseFile = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M449 469H63c-28 0-51-23-51-51V182c0-27 21-49 49-51 0-4 5-12 7-17v-2c12-20 28-51 59-51h147c33 2 50 31 62 51 4 9 8 15 10 17h105c28 0 51 22 51 51v236c-2 30-25 53-53 53zM63 151c-18 0-30 15-30 31v238c0 16 14 30 30 30h388c16 0 30-14 30-30V182c0-16-14-31-30-31H340l-2-2c-4-2-10-10-19-24-10-19-22-41-43-41H129c-18 0-31 20-41 39-10 16-14 24-20 26z',
      key: 'k0'
    })
  );
});
BrowseFile.displayName = 'BrowseFile';
export var BrowseFileDimensions = { height: 24, width: 24 };
