"use strict";

var _interopRequireDefault = require("/home/circleci/project/client-dashboard/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Text = exports.ListItem = exports.List = exports.Heading = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
const Heading = exports.Heading = _styledComponents.default.h1.withConfig({
  displayName: "Typography__Heading",
  componentId: "sc-1offnkt-0"
})(["font-weight:400;", ""], props => {
  switch (props.as) {
    case 'h2':
      return `
          font-size: 16px;
          line-height: 20px;
          color: ${props => props.theme.colors.primaryBlue};
        `;
    case 'h3':
      return `
          font-size: 14px;
          line-height: 1.5em;
        `;
    case 'h4':
      return `
          font-size: 12px;
          line-height: 14px;
        `;
    case 'h5':
      return `
          font-size: 11px;
          line-height: 13px;
        `;
    case 'h6':
      return `
          font-size: 10px;
        line-height: 12px;
        `;
    default:
      return `
          font-size: 24px;
          line-height: 26px;
          color: ${props => props.theme.colors.primaryBlue};
          font-weight: 400;
        `;
  }
});
const Text = exports.Text = _styledComponents.default.p.withConfig({
  displayName: "Typography__Text",
  componentId: "sc-1offnkt-1"
})(["", ""], props => {
  switch (props.as) {
    case 'blockquote':
      return `
          margin-left: 40px;
          margin-top: 20px;
          margin-bottom: 20px;
          font-size: 16px;
          line-height: 20px;
          color: ${props => props.theme.colors.primaryGray};
        `;
    default:
      return `
          line-height: 1.5em;
          font-weight: 400;
        `;
  }
});
const List = exports.List = _styledComponents.default.ul.withConfig({
  displayName: "Typography__List",
  componentId: "sc-1offnkt-2"
})(["font-size:14px;line-height:20px;margin-bottom:20px;padding-left:", ";color:", ";list-style-type:", ";& > li{display:", ";}", ""], props => props.inline || props.noBullets ? '0' : '2em', props => props.theme.colors.primaryGray, props => props.noBullets ? 'none' : 'disc', props => props.inline ? 'inline-block' : 'list-item', props => {
  switch (props.as) {
    case 'ol':
      return `
          list-style-type: ${props.noBullets ? 'none' : 'decimal'};
        `;
    default:
      return ``;
  }
});
const ListItem = exports.ListItem = _styledComponents.default.li.withConfig({
  displayName: "Typography__ListItem",
  componentId: "sc-1offnkt-3"
})(["font-size:14px;line-height:1.5em;font-weight:300;"]);