"use strict";

var _interopRequireDefault = require("/home/circleci/project/client-dashboard/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaginationNav = exports.PaginationItem = exports.Pagination = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
const Pagination = exports.Pagination = _styledComponents.default.div`
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-weight: 600;
`;
const PaginationItem = exports.PaginationItem = _styledComponents.default.div`
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  height: 32px;
  line-height: 32px;
  font-size: 11px;
  color: ${props => {
  if (props.selected === true) {
    return props.theme.colors.plain;
  } else if (props.disabled === true) {
    return props.theme.colors.grayRegular;
  } else {
    return props.theme.colors.secondaryBlueLight;
  }
}};
  background: ${props => props.selected ? props.theme.colors.secondaryBlueLight : props.theme.colors.plain};
  margin: 0px 2px;
  width: 32px;
  border: 1px solid
    ${props => props.selected ? props.theme.colors.secondaryBlueLight : props.theme.colors.grayRegular};
  ${props => {
  if (props.ellipsis === true) {
    return 'border: none;width: auto;font-size: 13px;';
  }
}}
`;
const PaginationNav = exports.PaginationNav = _styledComponents.default.div`
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  height: 32px;
  line-height: 32px;
  font-size: 11px;
  color: ${props => props.disabled ? props.theme.colors.grayRegular : '#21b0e8'};
  margin: 0px 5px;
`;