"use strict";

var _interopRequireDefault = require("/home/circleci/project/client-dashboard/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Tabs = exports.TabLink = exports.TabContent = exports.TabContainer = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
const Tabs = exports.Tabs = _styledComponents.default.div`
  border: 1px solid rgb(220, 220, 220);
`;
const TabContainer = exports.TabContainer = _styledComponents.default.div`
  background: ${props => props.theme.colors.plain};
  display: block;
`;
const TabLink = exports.TabLink = _styledComponents.default.a`
  display: inline-block;
  position: relative;
  padding: 10px;
  text-align: center;
  color: ${props => props.active ? props.theme.colors.plain : props.theme.colors.secondaryBlue};
  text-decoration: none;
  cursor: pointer;
  background: ${props => props.active ? props.theme.colors.secondaryBlue : props.theme.colors.plain};
  vertical-align: top;
  height: 30px;
  line-height: 30px;
  font-weight: 400;
  font-size: 14px;
  border-right: 1px solid ${props => props.theme.colors.grayRegular};

  &:last-child {
    border-right: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &::before {
    display: ${props => props.active ? 'inline-block' : 'none'};
    content: '';
    position: absolute;
    z-index: 1;
    width: 10px;
    height: 10px;
    background: ${props => props.theme.colors.secondaryBlue};
    transform: rotate(45deg);
    left: 50%;
    margin-left: -5px;
    bottom: -5px;
  }
`;
const TabContent = exports.TabContent = _styledComponents.default.div`
  display: ${props => props.active ? 'block' : 'none'};
  padding: 10px 30px 30px;
  overflow: hidden;
  background-color: ${props => props.theme.colors.grayLight};
  border-top: 1px solid ${props => props.theme.colors.grayRegular};
  font-weight: 300;
  font-size: 14px;
`;