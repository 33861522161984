import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var ScheduleCall = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M303 307l-61 61-33-32c-4-4-10-4-14 0-5 4-5 10 0 14l47 47 75-76c5-4 5-10 0-14-4-4-10-4-14 0zM451 57h-23V30c0-6-4-10-10-10s-10 4-10 10v27h-89V30c0-6-4-10-10-10s-10 4-10 10v27h-86V30c0-6-4-10-10-10s-10 4-10 10v27h-89V30c0-6-4-10-10-10s-10 4-10 10v27H61c-28 0-51 23-51 51v353c0 28 23 51 51 51h390c28 0 51-23 51-51V108c0-28-23-51-51-51zM61 78h23v14c0 6 4 10 10 10s10-4 10-10V78h89v14c0 6 4 10 10 10 4 0 10-4 10-10V78h86v14c0 6 4 10 10 10s10-4 10-10V78h89v14c0 6 4 10 10 10s10-4 10-10V78h23c16 0 30 14 30 30v99H31v-99c0-16 14-30 30-30zm390 413H61c-16 0-30-14-30-30V227h450v234c0 16-14 30-30 30z',
      key: 'k0'
    })
  );
});
ScheduleCall.displayName = 'ScheduleCall';
export var ScheduleCallDimensions = { height: 24, width: 24 };
