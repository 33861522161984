import { __assign } from 'tslib';
import * as React from 'react';
import { StyledIconBase } from '@styled-icons/styled-icon';
export var AskQuestion = React.forwardRef(function (props, ref) {
  var attrs = {
    fill: 'currentColor',
    xmlns: 'http://www.w3.org/2000/svg'
  };
  return React.createElement(
    StyledIconBase,
    __assign(
      {
        iconAttrs: attrs,
        iconVerticalAlign: 'middle',
        iconViewBox: '0 0 512 554'
      },
      props,
      { ref: ref }
    ),
    React.createElement('path', {
      d: 'M252 305c-4 0-6-2-6-6l-2-29c0-4 2-8 8-8 27-2 43-14 43-35 0-18-14-33-37-33-16 0-31 9-41 19-2 2-4 2-6 2-4 0-8-4-8-8 0-2 0-4 2-7 12-14 31-24 53-24 33 0 55 20 55 49 0 31-22 47-51 51l-2 21c-2 6-4 8-8 8zm0 20c6 0 12 4 12 13v4c0 6-6 12-12 12s-12-6-12-12v-4c0-6 6-13 12-13zm4 189C119 514 8 403 8 266S119 18 256 18s248 111 248 248-111 248-248 248zm0-475C131 39 29 141 29 266s102 227 227 227 227-102 227-227S381 39 256 39z',
      key: 'k0'
    })
  );
});
AskQuestion.displayName = 'AskQuestion';
export var AskQuestionDimensions = { height: 24, width: 24 };
